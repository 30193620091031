import axios from 'axios';
import $store from '../store/store'

const TOKEN_URI = '/auth/authenticate';
const USER_PATH = '/user/';
const CURRENT_USER_URI = '/internal_auth';
const CREATE_USER_FIRST_ACCESS_URI = '/user/createUserFirstAccess';
const GEOPLUGIN = '/geoinfo/geoPlugin';
const CREATE_USER_BY_MASTER_URI = '/user/createUserByMasterUser';
const SEND_EMAIL_FOR_CREATE_USER_WITH_EXISTING_ACCOUNT = '/user/sendEmailForCreateUserWithExistingAccount';
const CREATE_USER_WITH_EXISTING_ACCOUNT = '/user/createUserWithExistingAccount';
const FORGOT_USER_PASSWORD_URI = '/user/forgotPassword';
const RESEND_EMAIL_TOKEN = '/user/resendEmailToken';
const VALIDATE_EMAIL_TOKEN = '/user/validateEmailToken/';
const EXISTS_EMAIL_LOGIN = '/user/existsByLogin/';
const EXISTS_EMAIL_LOGIN_AND_APP_CODE = '/user/existsByLoginAndAppCode/';
const FIND_ALL_USERS_BY_FINANCIAL_GROUP = '/user/findAllByFinancialGroupExternalID';
const FIND_USER_BY_LOGIN_AND_APP_URI = '/user/findByLoginAndAppCode';
const FIND_MENU_URI = '/menu/findAllByAppCode/';
const FIND_ALL_PROFILES_BY_APP = '/app/findAllProfilesByAppCode/';
const GET_USER_ACCESS_NOT_VALID_TEMPLATE_URI = '/app/getUserAccessNotValidTemplate/';
const GET_USER_LOCKED_URI = '/app/getUserLockedTemplate/';
const GET_USE_TERMS_BY_APP = '/use_terms/getUserTermsByAppCode/';
const ACCEPT_USE_TERMS_URI = '/use_terms/acceptUseTerms';
const REFUSE_USE_TERMS_URI = '/use_terms/refuseUseTerms'
const USER_NOTIFICATION_PREFERENCE_URI = '/user_notification_preference/'
const USER_NOTIFICATION_PREFERENCE_FIND_ALL_URI = '/user_notification_preference/findAllByUserIdAndAppCode'

const {
    // VUE_APP_SECURITY_SERVICE_CLIENT_SECRET,
    VUE_APP_SECURITY_SERVICE_URL,
    VUE_APP_AUTH_SERVICE_URL,
// eslint-disable-next-line no-undef
} = process.env;

const tratarMensagemErroLogin = (error) => {
    let mensagem = "Acesso Inválido";

    if(error.response.data.error_description != null || error.response.data.message != null){
        if (error.response.data.message == "User Access Validation Exception" ||
            error.response.data.message == "Contract permissions from company user isn't present. Check Odoo: financial group, contract and subcontract." ||
            error.response.data.error_description ==  "User Access Validation Exception"
            ) {
            mensagem = 'ACCESS_VALIDATION_ERROR_MESSAGE';
        }else if(error.response.data.error_description == 'User Terms was not accepted' || error.response.data.message == 'User Terms was not accepted'){
            mensagem = 'O Termo de Uso não foi aceito.'
        }else if(error.response.data.error_description == 'Account permanently locked.' || error.response.data.error_description == 'Account permanently locked.' ){
            mensagem = 'Usuário bloqueado.'
        }else if(error.response.data.error_description == 'User Social Id Not Exists' || error.response.data.message == 'User Social Id Not Exists'){
            mensagem = 'USER_SOCIAL_ID_NOT_EXISTS'
        }else if(error.response.data.error_description == 'Valid Account But No User' || error.response.data.message == 'Valid Account But No User' ){
            mensagem = 'VALID_ACCOUNT_BUT_NO_USER'
        }else if(error.response.data.error_description == 'Account Login Not Found' || error.response.data.message == 'Account Login Not Found'){
            mensagem = 'ACCOUNT_LOGIN_NOT_FOUND'
        }else if(error.response.data.error_description == "Financial Group is not present or active." || error.response.data.message == "Financial Group is not present or active."){
            mensagem = "FINANCIAL_GROUP_NOT_PRESENT_OR_ACTIVE"
        } else if(error.response.data.error_description  == "RH Protegido is disabled access in app configuration." || error.response.data.message  == "RH Protegido is disabled access in app configuration.") {
            mensagem = "RH_PROTEGIDO_IS_DISABLED_APP_CONFIGURATION"
        }
    } else {
         mensagem = "Erro ao acessar servidor"
     }

    return mensagem;
}

const loginSecurityService = (user) => {
    const url = `${VUE_APP_AUTH_SERVICE_URL}${TOKEN_URI}`;
    // const client_credentials = btoa(`${$store.state.appCode}:${VUE_APP_SECURITY_SERVICE_CLIENT_SECRET}`);

    return axios({
        url,
        method: 'post',
        data: {
            email: user.login,
            password: user.password,
            app: $store.state.appCode
        },
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(response => {
            return {
                token: response.data.token,
                tinnyToken: response.data.tinnyToken
            }
        })
        .catch(error => {
            return {
                error: true,
                message: tratarMensagemErroLogin(error) }
        })
};

const getFullToken = (tinnyToken) => {
    const url = `${VUE_APP_AUTH_SERVICE_URL}/token/full`;
    // const client_credentials = btoa(`${$store.state.appCode}:${VUE_APP_SECURITY_SERVICE_CLIENT_SECRET}`);

    return axios({
        url,
        method: 'get',
        headers: {
            'Authorization': `Bearer ${tinnyToken}`,
            'Content-Type': 'application/json',
        },
    })
        .then(response => {
            return {
                token: response.data.token,
                tinnyToken: response.data.tinnyToken
            }
        })
        .catch(error => {
            return {
                error: true,
                message: tratarMensagemErroLogin(error) }
        })
};

const getCurrentUser = (token) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${CURRENT_USER_URI}/nocache`;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + token
        },
    }).then(response => response.data.principal.user)
        .catch(error => {
            return {
                error: true,
                message: tratarMensagemErroLogin(error)
            }
        });
};

const geoPlugin = (token) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${GEOPLUGIN}`;

    return axios({
        url: url,
        method: 'get'
    }).then(response => response).catch((error) => {});
};

const findUserNotificationPreferenceByUserIdAndAppCode = (token, appCode, encryptedUserId) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${USER_NOTIFICATION_PREFERENCE_FIND_ALL_URI}`;

    return axios({
        url: url,
        method: 'get',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        params: {
            appCode,
            encryptedUserId
        }
    }).then(response => response).catch((error) => {});
};

const saveUserNotificationPreference = (token, userNotificationPreferences) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${USER_NOTIFICATION_PREFERENCE_URI}`;

    return axios({
        url: url,
        method: 'post',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        data: userNotificationPreferences
    }).then(response => response.data);
};

const updateUserNotificationPreference = (token, userNotificationPreferences, appCode, encryptedUserId) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${USER_NOTIFICATION_PREFERENCE_URI}`;

    return axios({
        url: url,
        method: 'put',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        params: {
            appCode,
            encryptedUserId
        },
        data: userNotificationPreferences
    }).then(response => response.data);
};

const createUserFirstAccess = (user) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${CREATE_USER_FIRST_ACCESS_URI}`;

    return axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            name : user.name,
            login : user.login,
            email : user.email,
            cnpj : user.cnpj,
            password : user.password,
            appCode : $store.state.appCode,
            userRoleDescription : $store.state.userRole,
            socialId: user.socialId,
            socialType: user.socialType,
            socialAccessToken: user.socialAccessToken,
            authenticationProviderCode: user.authenticationProviderCode,
        },
    }).then(response => response.data);
};

const createUserByMasterUser = (user, token) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${CREATE_USER_BY_MASTER_URI}`;

    let contractsIDs = [];

    if(user.contracts) {
        for (let i = 0; i < user.contracts.length; i++) {
            contractsIDs.push(user.contracts[i].id);
        }
    }

    return axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token.access_token
        },
        data: {
            name : user.name,
            login : user.login,
            email : user.email,
            cpf : user.cpf,
            password : user.password,
            profileId: user.profile,
            externalContractIds: contractsIDs,
            appCode : $store.state.appCode,
            userRoleDescription : $store.state.userRole
        },
    }).then(response => response.data);
};

const updateUser = (user, token) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${USER_PATH}`;

    let contractsIDs = [];

    if(user.contracts) {
        for (let i = 0; i < user.contracts.length; i++) {
            contractsIDs.push(user.contracts[i].id);
        }
    }

    return axios({
        url: url + user.id,
        method: 'put',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token.access_token
        },
        data: {
            name : user.name,
            login : user.login,
            email : user.email,
            cpf : user.cpf,
            profileId: user.profile,
            externalContractIds: contractsIDs,
            appCode : $store.state.appCode,
            userRoleDescription : $store.state.userRole,
        },
    }).then(response => response.data);
};

const forgotUserPassword = (user) => {

    const url = `${VUE_APP_SECURITY_SERVICE_URL}${FORGOT_USER_PASSWORD_URI}`;

    return axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            login : user.email,
            appCode : $store.state.appCode,
            callback: $store.state.callbackServiceLogin,
            accountUrl: window.location.origin + '/serviceRedefinePassword',
            params: '&appCode=' + $store.state.appCode
        },
    }).then(response => response.data);
};


const resendEmailToken = () => {

    const url = `${VUE_APP_SECURITY_SERVICE_URL}${RESEND_EMAIL_TOKEN}`;

    return axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            emailToken : $store.state.token,
            appCode : $store.state.appCode,
            callback: $store.state.callbackServiceLogin,
            accountUrl: window.location.origin + '/serviceCreatePassword'
        },
    }).then(response => response.data);
};


const validateEmailToken = (emailToken) => {

    const url = `${VUE_APP_SECURITY_SERVICE_URL}${VALIDATE_EMAIL_TOKEN}` + emailToken;

    return axios({
        url: url,
        method: 'get',
        headers: {
            'Content-Type': 'application/json'
        },
    }).then(response => response.data);
};



const findAllUsersByFinancialGroup = (token) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${FIND_ALL_USERS_BY_FINANCIAL_GROUP}`;

    return axios({
        url: url,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + token.access_token
        },
    }).then(response => response.data).catch((error) => {});
};

const findAllProfilesByApp = (token) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${FIND_ALL_PROFILES_BY_APP}`;

    return axios({
        url: `${url}${$store.state.appCode}`,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + token.access_token
        },
    }).then(response => response.data).catch((error) => {});
};


const getUseTerms = () => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${GET_USE_TERMS_BY_APP}`;

    return axios({
        url: url + $store.state.appCode,
        method: 'get',
    }).then(response => response.data).catch((error) => {});
};

const acceptUseTerms = (useTerms) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${ACCEPT_USE_TERMS_URI}`;

    return axios({
        url: url,
        method: 'post',
        data : useTerms
    }).then(response => response.data).catch((error) => {});
};


const refuseUseTerms = (useTerms) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${REFUSE_USE_TERMS_URI}`;

    return axios({
        url: url,
        method: 'post',
        data : useTerms
    }).then(response => response.data).catch((error) => {});
};


const findUserByLogin = (login) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${FIND_USER_BY_LOGIN_AND_APP_URI}`;

    return axios({
        url: url,
        method: 'post',
        data : {
            login: login,
            appCode : $store.state.appCode,
        }
    }).then(response => response.data).catch((error) => {});
};



const getMenu = (token) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${FIND_MENU_URI}`;

    return axios({
        url: `${url}${$store.state.appCode}`,
        method: 'get',
        headers: {
            Authorization: 'Bearer ' + token.access_token
        },
    }).then(response => response.data).catch((error) => {});
};


const getUserAccessNotValidTemplate = () => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${GET_USER_ACCESS_NOT_VALID_TEMPLATE_URI}`;

    return axios({
        url: url + $store.state.appCode,
        method: 'get',
    }).then(response => response.data).catch((error) => {});
};


const getUserLocked = () => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${GET_USER_LOCKED_URI}`;

    return axios({
        url: url + $store.state.appCode,
        method: 'get',
    }).then(response => response.data).catch((error) => {});
};

const existsEmaillLogin = (login) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${EXISTS_EMAIL_LOGIN}` + login;

    return axios({
        url: url,
        method: 'get',
    })
};


const existsEmaillLoginAndAppCode = (login) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${EXISTS_EMAIL_LOGIN_AND_APP_CODE}` + login + '/' + $store.state.appCode;

    return axios({
        url: url,
        method: 'get',
    })
};


const sendEmailForCreateUserWithExistingAccount = (user) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${SEND_EMAIL_FOR_CREATE_USER_WITH_EXISTING_ACCOUNT}`;

    return axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            login : user.login,
            params: '&appCode=' + $store.state.appCode + '&userRole=' + $store.state.userRole,
            appCode: $store.state.appCode,
            authenticationProviderCode: user.authenticationProviderCode,
            callback: $store.state.callbackServiceLogin,
            accountUrl: window.location.origin + '/createUserWithExistingAccount' //'/serviceRedefinePassword',
        },
    }).then(response => response.data);
};


const createUserWithExistingAccount = (user) => {
    const url = `${VUE_APP_SECURITY_SERVICE_URL}${CREATE_USER_WITH_EXISTING_ACCOUNT}`;

    return axios({
        url: url,
        method: 'post',
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            password : user.password,
            appCode : $store.state.appCode,
            emailToken: $store.state.token,
            userRoleDescription : $store.state.userRole,
            authenticationProviderCode: user.authenticationProviderCode,
        },
    }).then(response => response.data);
};

export {
    getFullToken,
    loginSecurityService,
    getCurrentUser,
    createUserFirstAccess,
    createUserByMasterUser,
    forgotUserPassword,
    findAllUsersByFinancialGroup,
    updateUser,
    findUserByLogin,
    findAllProfilesByApp,
    getUseTerms,
    acceptUseTerms,
    refuseUseTerms,
    getUserAccessNotValidTemplate,
    getUserLocked,
    getMenu,
    resendEmailToken,
    validateEmailToken,
    existsEmaillLogin,
    existsEmaillLoginAndAppCode,
    sendEmailForCreateUserWithExistingAccount,
    createUserWithExistingAccount,
    geoPlugin,
    findUserNotificationPreferenceByUserIdAndAppCode,
    saveUserNotificationPreference,
    updateUserNotificationPreference
};
